// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-photography-js": () => import("./../../../src/pages/photography.js" /* webpackChunkName: "component---src-pages-photography-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-web-js": () => import("./../../../src/pages/web.js" /* webpackChunkName: "component---src-pages-web-js" */),
  "component---src-pages-wood-js": () => import("./../../../src/pages/wood.js" /* webpackChunkName: "component---src-pages-wood-js" */),
  "component---src-pages-writing-js": () => import("./../../../src/pages/writing.js" /* webpackChunkName: "component---src-pages-writing-js" */)
}

