export default {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  // not the same as space. 
  // sizes can also be object literals like sidebar: 400
  // not sure we really need a scale for width, height, etc.
  sizes: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: 'Inter, system-ui, sans-serif',
    heading: '"Source Serif Pro", sans-serif',
    monospace: 'Menlo, monospace', 
  },
  fontSizes: [
    12, 16, 18, 20, 24, 32
  ],
  fontWeights: {
    thin: '200',
    base: '400',
    semibold: '600',
    bold: '700',
    xbold: '800',
    black: '900',
  },
  lineHeights: {
    none: 1,
    heading: 1.25,
    copy: 1.5,
  },
  colors: {
    text: '#000',
    background: '#F4F3EE',
    primary: '#3333ee',
    secondary: '#26993F',
    accent: '#EE7203',
    highlight:'yellow',
    muted: '#888',
    whatever: '#2CEBD5',
  },
  // For MDX pages or import Styled to use on jsx page
  styles: {
    h1: {
      fontFamily: 'heading',
      fontSize: 3,
      fontWeight: 'black',
      color: 'secondary',
    },
  },
}